<template>
<div>
  <q-page padding>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Refund Policy" />
    </q-breadcrumbs>

<h5 class="q-my-md">
	Refund Policy
</h5>

<p>
  {{name}} is committed to providing the best service to our clients and customers.  If for whatever reason you are unhappy with our service, we are happy to refund any unused posting credits, or pro-rate and refund any unused membership payments.
</p>

  </q-page>
</div>

</template>

<script>
export default {
  data() {
    return {
      name: 'DegreeJobs',
      supportEmail: 'support@degreejobs.com',
    }
  }
}
</script>
